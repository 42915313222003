<template>
  <div v-if="dialog && playerConfig">
    <v-dialog :value="dialog" persistent transition="dialog-bottom-transition">
      
      <v-card>
        <v-toolbar>
          <v-btn icon @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t("title.user_config_betting") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :disabled="loading" text @click="validate">
              {{ $t("button.save") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <span class="text">កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត</span>
              <span class="pl-4 limit-text">
                {{ currencyFormatKh(limitConfig.total_bet_win_a_day) }}
              </span>
              <v-text-field
                class="mt-5"
                v-model="dataConfig.total_bet_win_a_day"
                type="number"
              />
              <span class="text"> កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត </span>
              <span class="pl-4 limit-text">
                {{ currencyFormatKh(limitConfig.total_bet_a_fight) }}</span
              >
              <v-text-field
                class="mt-5"
                v-model="dataConfig.total_bet_a_fight"
                type="number"
              />
              <span class="text"> ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង </span>
              <span class="pl-4 limit-text">
                {{
                  dataConfig.currency_type == 1
                    ? 1
                    : dataConfig.currency_type == 2
                    ? 5000
                    : dataConfig.currency_type == 3
                    ? 50
                    : "loading.."
                }}</span
              >
              <v-text-field
                class="mt-5"
                v-model="dataConfig.amount_min"
                type="number"
              />
              <span class="text"> ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
              <span class="pl-4 limit-text">
                {{ currencyFormatKh(limitConfig.amount_max) }}</span
              >
              <v-text-field
                class="mt-5"
                v-model="dataConfig.amount_max"
                type="number"
              />
              <span class="text"> Select Operation</span>
              <v-col col="12">
                <v-combobox
                  v-if="itemChannelu1.length > 0"
                  v-model="selectedChannelu1[0]"
                  :items="itemChannelu1"
                  label="Channel 1"
                  item-text="name"
                  item-value="item"
                  chips
                  hide-details="auto"
            style="width: 100%"
                ></v-combobox>
                <v-spacer></v-spacer>
              </v-col>
              <v-col col="12">
                <v-combobox
                  v-if="itemChannelu2.length > 0"
                  v-model="selectedChannelu2[0]"
                  :items="itemChannelu2"
                  label="Channel 2"
                  item-text="name"
                  item-value="item"
                  chips
                  hide-details="auto"
                 style="width: 100%"
                ></v-combobox>
              </v-col>
              <v-col col="12">
                <v-combobox
                  v-if="itemChannelu3.length > 0"
                  v-model="selectedChannelu3[0]"
                  :items="itemChannelu3"
                  label="Channel 3"
                  item-text="name"
                  item-value="item"
                  chips
                  hide-details="auto"
                  style="width: 100%"
                ></v-combobox>
              </v-col>
              <v-col col="12">
                <v-combobox
                  v-if="itemChannelu4.length > 0"
                  v-model="selectedChannelu4[0]"
                  :items="itemChannelu4"
                  label="Channel 4"
                  item-text="name"
                  item-value="item"
                  chips
                  hide-details="auto"
                  style="width: 100%"
                ></v-combobox>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          password: [
            (v) => !!v || "Password is required",
            (v) =>
              (!!v && v.length > 5) ||
              "Password must be greater than 5 characters",
          ],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    dataConfig: {
      type: Object,
      default: Object,
    },
    limitConfig: {
      type: Object,
      default: Object,
    },
    playerConfig:{
      type: Object,
      default: Object,
    },
  },
  watch: {
    dataConfig: function (newV) {
      this.correctSelectChanel(newV);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
      itemChannelu1() {
      let checkOption = [ 
        { "id": "11", "name": "Default", "vip_option": 1, "channel_type": 1 }, 
      { "id": "12", "name": "Vip 1", "vip_option": 2, "channel_type": 1 }, 
      { "id": "13", "name": "Vip 2", "vip_option": 3, "channel_type": 1 }];
      return checkOption;
    },
     itemChannelu2() {
      let checkOption = [ 
        { "id": "21", "name": "Default", "vip_option": 1, "channel_type": 2 }, 
      { "id": "22", "name": "Vip 1", "vip_option": 2, "channel_type": 2 }, 
      { "id": "23", "name": "Vip 2", "vip_option": 3, "channel_type": 2 }];
      return checkOption;
    },
     itemChannelu3() {
      let checkOption = [ 
        { "id": "31", "name": "Default", "vip_option": 1, "channel_type": 3 }, 
      { "id": "32", "name": "Vip 1", "vip_option": 2, "channel_type": 3 }, 
      { "id": "33", "name": "Vip 2", "vip_option": 3, "channel_type": 3 }];
      return checkOption;
    },
     itemChannelu4() {
      let checkOption = [ 
        { "id": "41", "name": "Default", "vip_option": 1, "channel_type": 4 }, 
      { "id": "42", "name": "Vip 1", "vip_option": 2, "channel_type": 4 }, 
      { "id": "43", "name": "Vip 2", "vip_option": 3, "channel_type": 4 }];
      return checkOption;
    },
    // itemChannelu1() {
    //   let checkOption = [];
    //   for (let j in this.limitConfig.channel_allow) {
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 1 &&
    //       this.limitConfig.channel_allow[j].vip_option == 1
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Default",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 1 &&
    //       this.limitConfig.channel_allow[j].vip_option == 2
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 1",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 1 &&
    //       this.limitConfig.channel_allow[j].vip_option == 3
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 2",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //   }
    //   return checkOption;
    // },
    // itemChannelu2() {
    //   let checkOption = [];
    //   for (let j in this.limitConfig.channel_allow) {
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 2 &&
    //       this.limitConfig.channel_allow[j].vip_option == 1
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Default",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 2 &&
    //       this.limitConfig.channel_allow[j].vip_option == 2
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 1",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 2 &&
    //       this.limitConfig.channel_allow[j].vip_option == 3
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 2",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //   }
    //   return checkOption;
    // },
    // itemChannelu3() {
    //   let checkOption = [];
    //   for (let j in this.limitConfig.channel_allow) {
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 3 &&
    //       this.limitConfig.channel_allow[j].vip_option == 1
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Default",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 3 &&
    //       this.limitConfig.channel_allow[j].vip_option == 2
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 1",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 3 &&
    //       this.limitConfig.channel_allow[j].vip_option == 3
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 2",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //   }
    //   return checkOption;
    // },
    // itemChannelu4() {
    //   let checkOption = [];
    //   for (let j in this.limitConfig.channel_allow) {
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 4 &&
    //       this.limitConfig.channel_allow[j].vip_option == 1
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Default",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 4 &&
    //       this.limitConfig.channel_allow[j].vip_option == 2
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 1",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //     if (
    //       this.limitConfig.channel_allow[j].channel_type == 4 &&
    //       this.limitConfig.channel_allow[j].vip_option == 3
    //     ) {
    //       checkOption.push({
    //         id:
    //           this.limitConfig.channel_allow[j].channel_type +
    //           "" +
    //           this.limitConfig.channel_allow[j].vip_option,
    //         name: "Vip 2",
    //         vip_option: this.limitConfig.channel_allow[j].vip_option,
    //         channel_type: this.limitConfig.channel_allow[j].channel_type,
    //       });
    //     }
    //   }
    //   return checkOption;
    // },
  },
  data: () => {
    return {
      showPassword: false,
      selectionChannelu1: [],
      selectedChannelu1: [],
      selectionChannelu2: [],
      selectedChannelu2: [],
      selectionChannelu3: [],
      selectedChannelu3: [],
      selectionChannelu4: [],
      selectedChannelu4: [],
      selectionChannel: [],
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.dataConfig.channel_allow = [];
        this.selectionChannel=[];
        if( this.itemChannelu1.length > 0 )   this.dataConfig.channel_allow.push(
          this.selectedChannelu1[0]
        );
        if( this.itemChannelu2.length > 0 )   this.dataConfig.channel_allow.push(
          this.selectedChannelu2[0]
        );
        if( this.itemChannelu3.length > 0 )   this.dataConfig.channel_allow.push(
          this.selectedChannelu3[0]
        );
        if( this.itemChannelu4.length > 0 )   this.dataConfig.channel_allow.push(
          this.selectedChannelu4[0]
        );
        this.$emit("submit");
        this.selectionChannel = [];
        this.selectionChannelu1 = [];
        this.selectedChannelu1 = [];
        this.selectionChannelu2 = [];
        this.selectedChannelu2 = [];
        this.selectionChannelu3 = [];
        this.selectedChannelu3 = [];
        this.selectionChannelu4 = [];
        this.selectedChannelu4 = [];
        this.dataConfig.channel_allow = [];
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    correctSelectChanel(item) {
      for (let i in item.channel_allow) {
        if (item.channel_allow[i].channel_type == 1) {
          this.selectionChannelu1.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item.channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (item.channel_allow[i].channel_type == 2) {
          this.selectionChannelu2.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item.channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (item.channel_allow[i].channel_type == 3) {
          this.selectionChannelu3.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item.channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
        if (item.channel_allow[i].channel_type == 4) {
          this.selectionChannelu4.push({
            id:
              item.channel_allow[i].channel_type +
              "" +
              item.channel_allow[i].vip_option,
            name:
              item.channel_allow[i].vip_option == 1
                ? "Default"
                : item.channel_allow[i].vip_option == 2
                ? "Vip 1"
                : item.channel_allow[i].vip_option == 3
                ? "Vip 2"
                : "",
            vip_option: item.channel_allow[i].vip_option,
            channel_type: item.channel_allow[i].channel_type,
          });
        }
      }

      this.selectedChannelu1 = this.selectionChannelu1;
      this.selectedChannelu2 = this.selectionChannelu2;
      this.selectedChannelu3 = this.selectionChannelu3;
      this.selectedChannelu4 = this.selectionChannelu4;
    },
  },
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}
.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
